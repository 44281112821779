import { CustomIcon } from './CustomIcon'
import { CustomIconProps } from './types'

export const GGPToken = (props: CustomIconProps): JSX.Element => {
  const { fill = '#5D43EF' } = props
  return (
    <CustomIcon
      fill="#5D43EF"
      height={24}
      viewBox="0 0 217 211"
      width={24}
      {...props}
      name="ggp-token"
    >
      <path
        d="M78.1655 9.83802L21.2793 51.0702C3.18384 64.1876 -4.38151 87.4491 2.52662 108.67L24.2441 175.394C31.1523 196.615 50.9712 210.985 73.3459 210.985H143.654C166.014 210.985 185.833 196.615 192.756 175.394L214.473 108.67C221.381 87.4491 213.816 64.1876 195.721 51.0702L138.849 9.83802C120.753 -3.27934 96.261 -3.27934 78.1655 9.83802V9.83802Z"
        fill={fill}
      />
      <path
        d="M116.197 152.569L100.993 152.394C99.182 152.38 97.6923 153.823 97.6777 155.63L97.5901 163.209C97.5171 169.214 102.337 174.126 108.354 174.198C114.371 174.271 119.293 169.462 119.366 163.457L119.454 155.878C119.468 154.071 118.022 152.584 116.211 152.569H116.197Z"
        fill="white"
      />
      <path
        d="M106.411 49.4523C85.2197 50.4726 67.7376 67.7438 66.4816 88.8773C65.7222 101.616 70.717 113.261 79.1441 121.452H79.1733L79.3047 121.612L82.1965 124.134L94.377 134.788C95.6915 135.939 97.371 136.566 99.1236 136.566H117.847C119.6 136.566 121.279 135.939 122.594 134.788L135.212 123.755L137.213 121.991L137.301 121.933C145.465 114.252 150.562 103.408 150.562 91.3842C150.562 67.5251 130.524 48.2718 106.397 49.4378L106.411 49.4523ZM108.5 71.7518C119.337 71.7518 128.173 80.5696 128.173 91.3987C128.173 102.228 119.337 111.031 108.5 111.031C97.6631 111.031 88.8272 102.213 88.8272 91.3987C88.8272 80.5842 97.6631 71.7518 108.5 71.7518Z"
        fill="white"
      />
    </CustomIcon>
  )
}
