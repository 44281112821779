export default function WelcomeMountainIcon() {
  return (
    <svg
      fill="none"
      height="300"
      viewBox="0 0 445 300"
      width="445"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.842 176.995H144.786C144.08 171.7 139.217 167.977 133.918 168.683C131.536 168.997 129.48 170.161 127.996 171.815C125.862 167.072 121.154 163.873 115.849 163.746C113.434 157.106 107.071 152.362 99.5997 152.362C92.6522 152.362 86.6696 156.46 83.9237 162.373C83.1793 162.246 82.4129 162.18 81.6354 162.18C74.1089 162.18 68.0105 168.28 68.0105 175.809H60.7322C59.9106 175.809 59.2434 176.476 59.2434 177.298C59.2434 178.12 59.9106 178.787 60.7322 178.787H153.978"
        fill="url(#paint0_linear_4257_8647)"
      />
      <path
        d="M176.48 197.22H120.531C119.709 197.22 119.042 196.553 119.042 195.731C119.042 194.909 119.709 194.242 120.531 194.242H127.484C128.063 189.895 132.055 186.845 136.394 187.425C138.346 187.684 140.033 188.632 141.252 189.995C143 186.101 146.865 183.481 151.21 183.382C153.189 177.938 158.406 174.044 164.537 174.044C170.233 174.044 175.14 177.408 177.395 182.251C177.395 182.251 175.857 196.602 176.475 197.22H176.48Z"
        fill="url(#paint1_linear_4257_8647)"
      />
      <path
        d="M49.4286 112.976H74.0482C75.0627 112.976 75.8898 113.803 75.8898 114.818V125.237C75.8898 128.689 73.0888 131.491 69.637 131.491H53.8397C50.388 131.491 47.5869 128.689 47.5869 125.237V114.818C47.5869 113.803 48.414 112.976 49.4286 112.976Z"
        fill="#CC7323"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M687.344 297.922C685.442 295.043 681.301 290.437 673.383 288.203C672.131 269.307 655.628 253.511 636.577 253.329C624.579 253.219 613.877 258.778 606.984 267.498C604.883 270.151 601.123 270.813 598.261 269.004C593.243 265.827 587.294 263.985 580.909 263.985C576.702 263.985 572.682 264.79 568.993 266.246C565.806 267.504 562.184 266.108 560.662 263.036C552.876 247.339 536.682 236.545 517.973 236.545C502.154 236.545 488.137 244.256 479.475 256.131C477.28 259.137 473.062 259.81 470.101 257.554C463.474 252.502 455.203 249.507 446.232 249.507C441.765 249.507 437.475 250.251 433.472 251.625C432.353 252.005 431.19 252.082 430.087 251.878C428.416 251.569 426.878 250.632 425.869 249.159C415.508 234.085 398.15 224.202 378.482 224.202C374.766 224.202 371.132 224.555 367.609 225.233C350.025 228.597 335.286 239.987 327.346 255.441C326.37 257.339 324.644 258.547 322.753 258.938C320.978 259.297 319.053 258.944 317.46 257.747C308.489 251.023 297.35 247.036 285.275 247.036C283.268 247.036 281.283 247.146 279.336 247.361C276.381 247.692 273.624 245.96 272.499 243.208C268.783 234.146 259.883 227.765 249.49 227.765C245.332 227.765 241.423 228.79 237.977 230.589C227.147 220.969 212.888 215.123 197.262 215.123C179.838 215.123 164.118 222.387 152.952 234.047C151.348 235.718 149.015 236.479 146.727 236.11C145.431 235.894 144.097 235.79 142.74 235.79C139.608 235.79 136.609 236.369 133.846 237.433C131.564 238.31 128.989 238.045 127.009 236.606C118.507 230.418 108.047 226.761 96.7268 226.761C91.0474 226.761 85.5776 227.682 80.4662 229.381C64.1009 234.824 51.3804 248.255 46.9251 265.049C45.7727 264.884 44.5982 264.796 43.4017 264.796C29.6555 264.796 18.5119 275.953 18.534 289.709H22.9837C15.5565 291.38 7.71569 293.857 2.1687 297.464C0.823302 298.341 1.4574 300.437 3.06195 300.437H685.993C687.289 300.437 688.055 298.997 687.338 297.922H687.344Z"
        fill="white"
      />
      <path
        d="M1 298.004C1 298.004 16.3507 286.261 38.0204 288.523"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M49.4285 112.977L24.478 74.8701"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M55.1744 112.408L49.4785 91.8848"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M67.7402 112.408L73.4361 92.4365"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M47.6919 118.183H54.8159"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M48.5022 121.855H51.8326"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M47.6919 125.347H56.1227"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M26.7332 17.3691H34.7669"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path d="M21.4897 25.583H29.529" stroke="black" strokeLinecap="round" strokeMiterlimit="10" />
      <path
        d="M23.938 21.3848H28.1286"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3945 29.4268H23.5906"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M35.4453 58.5928H39.6414"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M40.7993 55.1123H44.9899"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M583.964 231.046L584.041 230.881L630.396 253.412V253.423C621.304 255.066 613.358 260.019 607.85 266.985C605.749 269.644 601.994 270.306 599.132 268.491C594.109 265.314 588.16 263.477 581.775 263.477C577.568 263.477 573.548 264.277 569.859 265.733C566.672 266.991 563.055 265.595 561.533 262.529C553.742 246.826 537.553 236.032 518.839 236.032C503.019 236.032 489.003 243.748 480.341 255.618C478.146 258.624 473.934 259.297 470.973 257.041C464.345 251.994 456.068 248.994 447.097 248.994C442.631 248.994 438.341 249.744 434.338 251.112C433.219 251.492 432.055 251.569 430.953 251.365C429.276 251.062 427.749 250.119 426.734 248.646C416.374 233.572 399.016 223.688 379.348 223.688C375.632 223.688 371.992 224.041 368.474 224.72C350.891 228.084 336.157 239.474 328.212 254.928C327.186 256.925 325.328 258.166 323.316 258.48C321.628 258.745 319.831 258.364 318.325 257.234C313.159 253.362 307.27 250.395 300.896 248.58L300.907 248.536C300.907 248.536 304.607 200.33 334.222 173.172C363.843 146.014 359.52 125.645 341.01 123.18C322.5 120.709 363.843 46.0287 363.843 46.0287V46.0122C370.443 43.5192 378.262 45.2455 383.147 51.0699C357.27 38.1581 368.684 80.12 360.617 101.393C352.545 122.672 375.284 104.328 380.418 153.482C382.993 178.13 391.467 191.897 399.281 199.492C402.981 203.094 409.272 200.077 408.704 194.942C408.114 189.625 410.121 184.545 419.291 183.563C439.824 181.363 481.631 215.845 526.371 219.513C564.119 222.607 579.944 229.094 583.964 231.046Z"
        fill="#F1FBFF"
      />
      <path
        d="M584.041 230.881L583.964 231.046C579.944 229.094 564.119 222.608 526.371 219.513C481.631 215.846 439.825 181.363 419.291 183.563C410.121 184.545 408.114 189.625 408.704 194.942C409.272 200.077 402.981 203.094 399.281 199.492C391.468 191.897 382.993 178.131 380.418 153.482C375.284 104.328 352.545 122.672 360.617 101.393C368.684 80.1201 357.27 38.1582 383.147 51.07L485.871 173.487C494.914 184.269 506.129 193.011 518.778 199.161L584.041 230.881Z"
        fill="white"
      />
      <path
        d="M430.782 252.159C435.479 253.411 442.846 256.528 451.012 264.266C436.577 252.104 421.628 261.641 418.574 263.841C418.293 264.04 417.896 263.902 417.796 263.571C404.42 220.296 368.496 224.83 368.496 224.83L368.474 224.72C371.992 224.041 375.631 223.688 379.348 223.688C399.016 223.688 416.374 233.572 426.734 248.646C427.749 250.119 429.276 251.062 430.952 251.365L430.782 252.159Z"
        fill="white"
      />
      <path
        d="M392.609 14.2436C392.951 14.4145 393.056 14.8503 392.83 15.1591C391.473 16.9958 390.117 19.213 388.76 21.684C390.04 24.0501 391.319 26.6314 392.598 29.5491C392.819 30.0565 392.317 30.5639 391.804 30.3599C383.969 27.2326 376.128 35.732 368.293 31.0769V14.5966C376.398 19.4116 384.504 10.1566 392.609 14.2436Z"
        fill="#CE2828"
      />
      <path
        d="M320.476 90.1367L320.289 89.9657L357.326 50.3755C359.206 48.3623 361.439 46.9117 363.837 46.0127V46.0292C363.837 46.0292 322.5 120.709 341.01 123.18C359.52 125.645 363.843 146.014 334.222 173.173C304.607 200.331 300.907 248.536 300.907 248.536L300.896 248.58C296.209 247.246 291.258 246.529 286.141 246.529C284.134 246.529 282.154 246.639 280.202 246.854C277.247 247.18 274.496 245.448 273.365 242.695C273.365 242.695 293.105 216.414 256.085 214.158C219.064 211.897 335.54 126.092 305.291 131.966C276.111 137.631 317.526 93.2695 320.476 90.1367Z"
        fill="#CCD9F2"
      />
      <path
        d="M323.315 258.481L323.36 258.745C319.775 259.413 316.996 260.659 314.846 262.165L313.87 261.123C294.891 250.114 275.273 253.147 266.285 257.345C264.482 258.188 262.376 257.019 262.183 255.039C259.801 230.379 238.842 230.081 238.842 230.081C242.288 228.278 246.198 227.252 250.355 227.252C260.749 227.252 269.654 233.639 273.365 242.695C274.495 245.448 277.247 247.179 280.202 246.854C282.154 246.639 284.134 246.529 286.141 246.529C291.258 246.529 296.209 247.246 300.896 248.58C307.27 250.395 313.159 253.362 318.325 257.234C319.831 258.365 321.628 258.745 323.315 258.481Z"
        fill="white"
      />
      <path
        d="M244.742 170.706H244.753L290.58 121.723L320.288 89.9648L320.476 90.1358C317.526 93.2686 276.111 137.63 305.291 131.965C335.54 126.091 219.064 211.896 256.084 214.157C293.105 216.413 273.365 242.695 273.365 242.695C269.654 233.638 260.749 227.251 250.356 227.251C246.198 227.251 242.289 228.277 238.842 230.081C228.013 220.456 213.754 214.61 198.128 214.61C180.709 214.61 164.984 221.874 153.818 233.539C152.219 235.21 149.881 235.966 147.593 235.596C146.297 235.387 144.968 235.276 143.606 235.276C140.474 235.276 137.475 235.861 134.712 236.92C132.43 237.797 129.855 237.532 127.875 236.093C125.614 234.443 123.21 232.976 120.69 231.713L171.121 188.157L177.754 182.432L178.283 181.974C182.59 178.251 187.949 175.974 193.606 175.45L244.742 170.706Z"
        fill="#ABC5EF"
      />
      <path
        d="M290.579 121.723L244.753 170.7H244.742L193.606 175.449C187.949 175.973 182.589 178.251 178.283 181.974L177.754 182.432L177.528 182.173C175.272 177.325 170.365 173.966 164.669 173.966C163.153 173.966 161.692 174.203 160.319 174.644C158.549 175.218 156.928 176.128 155.538 177.297L155.207 176.916H144.913C144.207 171.616 139.344 167.893 134.045 168.599C131.668 168.919 129.606 170.077 128.123 171.737C126.254 167.584 122.416 164.611 117.933 163.856L117.321 162.929L156.073 137.221C159.282 135.087 163.396 134.861 166.82 136.626L170.233 138.385C173.861 140.255 178.244 139.88 181.503 137.42L238.247 94.5699C241.892 91.8177 246.882 91.6963 250.648 94.2721L289.945 121.127L290.579 121.723Z"
        fill="#3F3DA3"
      />
      <path
        d="M120.69 231.713C123.21 232.976 125.614 234.443 127.875 236.092C129.854 237.532 132.429 237.797 134.712 236.92C137.475 235.861 140.474 235.276 143.606 235.276C144.968 235.276 146.297 235.386 147.593 235.596C149.881 235.965 152.219 235.21 153.818 233.539C164.984 221.873 180.709 214.609 198.128 214.609C174.032 215.453 158.152 237.67 155.053 250.587C154.496 252.909 152.026 254.222 149.76 253.466C142.139 250.929 136.383 252.357 130.819 255.628C128.415 257.04 124.561 257.018 123.552 254.42C110.837 221.708 81.933 228.817 81.3706 228.955L81.343 228.872C86.4489 227.168 91.9132 226.253 97.5925 226.253C105.896 226.253 113.743 228.216 120.69 231.713Z"
        fill="white"
      />
      <path
        d="M61.7358 1C87.2928 1 108.009 21.7217 108.009 47.2861C108.009 72.8504 87.2928 93.5777 61.7358 93.5777C73.7782 93.5777 83.5434 72.8559 83.5434 47.2861C83.5434 21.7162 73.7782 1 61.7358 1Z"
        fill="#FF9391"
      />
      <path
        d="M61.7353 1C73.7776 1 83.5428 21.7217 83.5428 47.2861C83.5428 72.8504 73.7776 93.5777 61.7353 93.5777C49.6929 93.5777 39.9277 72.8559 39.9277 47.2861C39.9277 21.7162 49.6929 1 61.7353 1ZM71.8202 50.8436C74.6764 48.152 76.4629 44.3463 76.4629 40.1325C76.4629 31.7655 69.4492 25.0145 61.0019 25.4282H61.0074C53.5912 25.7867 47.4763 31.8427 47.0352 39.2555C46.7705 43.7176 48.5184 47.8045 51.4684 50.6726H51.4739L51.5235 50.7333L52.5325 51.6157L56.7948 55.3497C57.258 55.7524 57.8424 55.973 58.46 55.973H65.0105C65.6226 55.973 66.2126 55.7524 66.6757 55.3497L71.0869 51.4834L71.7871 50.8656L71.8202 50.8436ZM65.5729 62.746C65.5729 62.1117 65.0712 61.5878 64.4371 61.5878L59.1161 61.5216C58.482 61.5216 57.9637 62.0235 57.9582 62.6578L57.9251 65.3163C57.9031 67.4232 59.5903 69.144 61.6967 69.1661C63.803 69.1937 65.5233 67.5059 65.5509 65.4045L65.5785 62.746H65.5729Z"
        fill="#FF6464"
      />
      <path
        d="M76.4636 40.1324C76.4636 44.3462 74.6771 48.1519 71.8209 50.8435L71.7878 50.8655L71.0875 51.4833L66.6764 55.3496C66.2132 55.7523 65.6233 55.9729 65.0112 55.9729H58.4607C57.8431 55.9729 57.2587 55.7523 56.7955 55.3496L52.5332 51.6156L51.5242 50.7331L51.4746 50.6725H51.469C48.5191 47.8044 46.7712 43.7174 47.0359 39.2554C47.477 31.8426 53.5919 25.7866 61.0081 25.4336H61.0026C69.4499 25.0144 76.4636 31.7653 76.4636 40.1324ZM68.6283 40.1324C68.6283 36.3377 65.535 33.2435 61.7415 33.2435C57.9479 33.2435 54.8546 36.3432 54.8546 40.1324C54.8546 43.9215 57.9479 47.0157 61.7415 47.0157C65.535 47.0157 68.6283 43.927 68.6283 40.1324Z"
        fill="white"
      />
      <path
        d="M61.7409 33.249C65.5344 33.249 68.6277 36.3432 68.6277 40.1379C68.6277 43.9325 65.5344 47.0212 61.7409 47.0212C57.9473 47.0212 54.854 43.927 54.854 40.1379C54.854 36.3487 57.9473 33.249 61.7409 33.249Z"
        fill="#FF6464"
      />
      <path
        d="M64.4373 61.5877C65.0714 61.5877 65.5787 62.1117 65.5732 62.746L65.5456 65.4044C65.5181 67.5058 63.7977 69.1936 61.6914 69.166C59.5851 69.1439 57.8978 67.4231 57.9199 65.3162L57.953 62.6577C57.953 62.0234 58.4768 61.516 59.1109 61.5215L64.4318 61.5877H64.4373Z"
        fill="white"
      />
      <path
        d="M61.7355 1C49.6932 1 39.928 21.7217 39.928 47.2861C39.928 72.8504 49.6932 93.5777 61.7355 93.5777C36.1786 93.5777 15.4573 72.8559 15.4573 47.2861C15.4573 21.7162 36.1786 1 61.7355 1Z"
        fill="#CE2828"
      />
      <path
        d="M120.685 231.714H120.691L171.121 188.158L177.754 182.433L178.283 181.975C182.59 178.252 187.949 175.974 193.607 175.45L244.743 170.707H244.754L290.58 121.723L320.289 89.9652L357.326 50.3749C359.206 48.3618 361.439 46.9112 363.838 46.0122C370.443 43.5192 378.262 45.2455 383.147 51.0699L485.871 173.486C494.914 184.269 506.129 193.011 518.778 199.161L584.041 230.881L630.397 253.412"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M674.32 289.179C673.862 269.632 656.99 253.003 637.443 252.821C635.033 252.799 632.679 253.003 630.396 253.422C621.304 255.066 613.358 260.018 607.85 266.985C605.749 269.643 601.994 270.305 599.132 268.49C594.109 265.313 588.16 263.477 581.774 263.477C577.567 263.477 573.548 264.276 569.859 265.733C566.672 266.99 563.055 265.595 561.533 262.528C553.742 246.825 537.553 236.032 518.839 236.032C503.019 236.032 489.003 243.748 480.341 255.617C478.146 258.623 473.933 259.296 470.972 257.04C464.345 251.993 456.068 248.993 447.097 248.993C442.631 248.993 438.341 249.743 434.338 251.111C433.219 251.492 432.055 251.569 430.952 251.365C429.276 251.061 427.749 250.118 426.734 248.646C416.374 233.572 399.016 223.688 379.348 223.688C375.631 223.688 371.992 224.041 368.474 224.719C350.89 228.084 336.157 239.473 328.212 254.928C327.186 256.924 325.328 258.165 323.315 258.48C321.628 258.744 319.831 258.364 318.325 257.233C313.159 253.361 307.27 250.394 300.896 248.579C296.209 247.245 291.258 246.528 286.141 246.528C284.134 246.528 282.154 246.638 280.202 246.853C277.247 247.178 274.495 245.447 273.365 242.694C269.654 233.638 260.749 227.251 250.355 227.251C246.198 227.251 242.289 228.277 238.842 230.08C228.013 220.456 213.754 214.609 198.128 214.609C180.709 214.609 164.983 221.873 153.818 233.539C152.219 235.21 149.881 235.965 147.593 235.596C146.297 235.386 144.968 235.276 143.606 235.276C140.474 235.276 137.475 235.861 134.712 236.92C132.429 237.797 129.854 237.532 127.875 236.092C125.614 234.443 123.21 232.976 120.69 231.713C113.743 228.216 105.896 226.253 97.5925 226.253C91.9131 226.253 86.4488 227.168 81.343 228.872C64.9722 234.305 52.2516 247.735 47.7908 264.536C46.6384 264.376 45.4639 264.282 44.2674 264.282C30.5213 264.282 19.3776 275.44 19.3997 289.195"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M363.843 46.0293C363.843 46.0293 322.5 120.709 341.01 123.18C359.52 125.646 363.843 146.014 334.222 173.173C304.607 200.331 300.907 248.536 300.907 248.536"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M244.753 170.701L244.742 170.707L202.809 195.19"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M585.05 231.614C585.05 231.614 584.708 231.41 583.964 231.046C579.944 229.094 564.119 222.608 526.371 219.513C481.631 215.846 439.825 181.363 419.291 183.563C410.121 184.545 408.114 189.625 408.704 194.942C409.272 200.077 402.981 203.094 399.281 199.492C391.468 191.897 382.993 178.131 380.418 153.482C375.284 104.328 352.545 122.672 360.617 101.393C368.684 80.1201 357.27 38.1582 383.147 51.07"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M81.3599 228.961C81.3599 228.961 81.3654 228.961 81.3709 228.955C81.9333 228.817 110.837 221.708 123.552 254.42C124.561 257.018 128.416 257.04 130.82 255.628C136.383 252.357 142.14 250.929 149.76 253.466C152.026 254.222 154.496 252.909 155.053 250.587C158.152 237.67 174.032 215.453 198.128 214.609"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M320.641 89.9648C320.641 89.9648 320.586 90.0255 320.476 90.1358C317.526 93.2686 276.111 137.63 305.291 131.965C335.54 126.091 219.064 211.896 256.085 214.157C293.105 216.413 273.365 242.695 273.365 242.695"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M154.722 273.317C146.413 273.317 139.311 278.479 136.444 285.776C136.052 286.775 134.839 287.172 133.968 286.543C131.509 284.756 128.481 283.703 125.206 283.703C123.96 283.703 122.747 283.857 121.589 284.149C120.839 284.337 120.073 284 119.709 283.322C116.417 277.106 109.883 272.865 102.351 272.865C97.1073 272.865 92.3433 274.928 88.8199 278.281C88.1472 278.921 87.0775 278.838 86.4655 278.138C81.503 272.418 74.1805 268.8 66.0144 268.8"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M407.238 262.468C399.562 265.661 394.986 273.157 395.134 280.994C395.157 282.064 394.192 282.897 393.144 282.649C390.188 281.949 386.99 282.136 383.969 283.394C382.816 283.873 381.752 284.48 380.793 285.197C380.175 285.655 379.337 285.644 378.747 285.153C373.316 280.68 365.657 279.273 358.704 282.164C353.857 284.177 350.251 287.911 348.288 292.362C347.913 293.211 346.893 293.548 346.061 293.134C339.279 289.758 331.129 289.229 323.586 292.367"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M238.842 230.081C238.842 230.081 259.801 230.379 262.183 255.039C262.376 257.019 264.482 258.188 266.285 257.344C275.273 253.147 294.891 250.113 313.87 261.122"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M307.551 273.769C307.551 273.769 308.252 266.77 314.846 262.165C316.997 260.659 319.776 259.412 323.36 258.745C324.076 258.607 324.826 258.497 325.609 258.414"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M61.7355 1C87.2925 1 108.008 21.7217 108.008 47.2861C108.008 72.8504 87.2925 93.5777 61.7355 93.5777C36.1786 93.5777 15.4573 72.8559 15.4573 47.2861C15.4573 21.7162 36.1786 1 61.7355 1Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M117.03 163.122L117.322 162.929L156.074 137.221C159.283 135.087 163.396 134.861 166.82 136.626L170.233 138.385C173.861 140.255 178.245 139.88 181.504 137.42L238.247 94.5699C241.892 91.8177 246.882 91.6963 250.648 94.2721L289.946 121.127"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M59.3704 175.726H68.143C68.143 168.203 74.2414 162.103 81.7624 162.103C82.5453 162.103 83.3062 162.169 84.0506 162.296C86.565 156.896 91.7811 153.002 97.9677 152.379C98.5467 152.313 99.1367 152.285 99.7322 152.285C107.204 152.285 113.567 157.028 115.976 163.669C116.638 163.686 117.294 163.746 117.934 163.857C122.416 164.612 126.254 167.585 128.123 171.738C129.607 170.078 131.669 168.92 134.045 168.6C139.344 167.894 144.207 171.617 144.913 176.917H155.208"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.528 182.174C175.273 177.326 170.365 173.967 164.67 173.967C163.153 173.967 161.692 174.204 160.319 174.645C158.549 175.219 156.928 176.129 155.538 177.298C153.658 178.881 152.203 180.949 151.342 183.299C146.997 183.398 143.132 186.024 141.384 189.918C140.166 188.555 138.478 187.607 136.526 187.347C132.182 186.768 128.189 189.818 127.61 194.165H119.169"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.7581 152.341C97.7581 152.341 97.8297 152.352 97.9676 152.379C99.6052 152.677 110.401 155.259 110.401 170.487C114.895 169.323 120.553 171.816 122.218 176.813"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.319 174.645C160.319 174.645 169.307 176.145 170.806 187.793"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.0208 112.977L98.9712 74.8701"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M61.7353 1C73.7776 1 83.5428 21.7217 83.5428 47.2861C83.5428 72.8504 73.7776 93.5777 61.7353 93.5777C49.6929 93.5777 39.9277 72.8559 39.9277 47.2861C39.9277 21.7162 49.6929 1 61.7353 1Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M64.4318 61.5877L59.1109 61.5215C58.4768 61.5215 57.9585 62.0234 57.953 62.6577L57.9199 65.3161C57.8978 67.4231 59.5851 69.1439 61.6914 69.1659C63.7977 69.1935 65.5181 67.5058 65.5456 65.4044L65.5732 62.7459C65.5732 62.1116 65.0714 61.5877 64.4373 61.5877H64.4318Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M61.0081 25.4337C53.5919 25.7867 47.477 31.8427 47.0359 39.2556C46.7712 43.7176 48.5191 47.8046 51.469 50.6726H51.4746L51.5242 50.7333L52.5332 51.6158L56.7955 55.3498C57.2587 55.7524 57.8431 55.973 58.4607 55.973H65.0112C65.6233 55.973 66.2132 55.7524 66.6764 55.3498L71.0875 51.4834L71.7878 50.8657L71.8209 50.8436C74.6771 48.1521 76.4636 44.3464 76.4636 40.1325C76.4636 31.7655 69.4499 25.0145 61.0026 25.4282H61.0081V25.4337Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M61.7409 33.249C65.5344 33.249 68.6277 36.3432 68.6277 40.1379C68.6277 43.9325 65.5344 47.0212 61.7409 47.0212C57.9473 47.0212 54.854 43.927 54.854 40.1379C54.854 36.3487 57.9473 33.249 61.7409 33.249Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M368.293 14.5955V11.6006"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M368.293 44.0596V31.0762"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M368.293 14.5966C376.398 19.4116 384.504 10.1566 392.609 14.2436C392.951 14.4145 393.056 14.8503 392.83 15.1591C391.473 16.9958 390.117 19.213 388.76 21.684C390.04 24.0501 391.319 26.6314 392.598 29.5491C392.819 30.0565 392.317 30.5639 391.804 30.3599C383.969 27.2326 376.128 35.732 368.293 31.0769V14.5966Z"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M381.912 30.3974V26.2773"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M378.901 15.6602V19.4659"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M381.912 23.1885V21.5283"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M73.436 17.5186H77.6266"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M79.7222 19.9238H83.9127"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        d="M368.497 224.829C368.497 224.829 404.42 220.296 417.796 263.57C417.896 263.901 418.293 264.039 418.574 263.841C421.629 261.64 436.577 252.104 451.012 264.265C442.846 256.527 435.48 253.411 430.782 252.159C427.462 251.276 425.472 251.32 425.472 251.32"
        stroke="black"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_4257_8647"
          x1="127.996"
          x2="87.8268"
          y1="156.102"
          y2="196.811"
        >
          <stop stopColor="#F8FDFF" />
          <stop offset="1" stopColor="#F8FDFF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_4257_8647"
          x1="158.042"
          x2="117.872"
          y1="185.748"
          y2="226.457"
        >
          <stop stopColor="#F8FDFF" />
          <stop offset="1" stopColor="#F8FDFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
